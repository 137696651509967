<template>
  <dash-page-new
      :title="$t('TemplatesAll')"
      :subtitle="'Templates'"
      :root="$t('Templates')"
      icon="mdi-pencil-ruler"
  >

    <template #header_action>
      <v-btn @click="openAddTemplate" :color="wsATTENTION" dark class="noCaps">
        <v-icon>mdi-plus</v-icon>
        {{ $t('CreateNew') }}
      </v-btn>
    </template>

    <ws-data-table
        :items="items"
        :headers="headers"
        :row-action="selectTemplate"
        class="mt-5"
    >
      <template #item.name="{item}">
        <router-link class="black--text linkHover noUnderline" :to="localeLink('avalon_editor/templates/' + item.id )">
          <h5>{{ item.name }}</h5>
        </router-link>

      </template>
      <template #item.uploads="{item}">

        <div class="d-flex align-center">
          <v-icon class="mr-2" :color="wsACCENT"> mdi-download </v-icon>
          <h5> {{ item.uploads }}</h5>
        </div>

      </template>
    </ws-data-table>

    <template #dialog>
      <ws-dialog
          v-model="displayTemplateDialog"
          :title="$t('AddNewTemplate')"
          @save="addTemplate"
      >

        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

        <ws-switch
            v-model="entityData.active"
            :label="$t('IsPublished')"
            :placeholder="$t('IsTemplatePublished')"
        />
      </ws-dialog>

    </template>


  </dash-page-new>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "adminTemplates",
  data() {
    return {
      items : [],
      categories : [],
      displayTemplateDialog : false,
      entityData : {},
    }
  },
  computed : {
    headers() {
      return [
        { text : 'Name' , value : 'name' },
        { text : 'Uploads' , value : 'uploads' },
        { text : 'Category' , value : 'category_name' },
      ]
    }
  },
  methods : {
    ...mapActions('avalon' , [
        'GET_ADMIN_TEMPLATES',
        'ADD_SITE_TEMPLATE'
    ]),
    async addTemplate() {
      let result = await this.ADD_SITE_TEMPLATE(this.entityData)
      if ( !result ) {
        return
      }
      this.items.unshift(result)
    },
    openAddTemplate() {
      this.entityData = {}
      this.displayTemplateDialog = true
    },
    selectTemplate(item) {
      this.$router.push(this.localeLink('avalon_editor/templates/' + item.id ) )
    }
  },
  async mounted() {
    let result = await this.GET_ADMIN_TEMPLATES()
    if ( !result ) {
      return
    }
    result.templates.reverse()
    this.items = result.templates
    this.categories = result.categories
  }
}
</script>

<style scoped>

</style>